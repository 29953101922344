// Google Fonts
:root {
  --ion-font-family: 'Inter', sans-serif;
  --font-secondary: 'Mulish', sans-serif;
}

// Icomoon
@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon/icomoon.eot?yy8oeg');
  src: url('/assets/fonts/icomoon/icomoon.eot?yy8oeg#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon/icomoon.ttf?yy8oeg') format('truetype'),
    url('/assets/fonts/icomoon/icomoon.woff?yy8oeg') format('woff'),
    url('/assets/fonts/icomoon/icomoon.svg?yy8oeg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout:before {
  content: "\e916";
}
.icon-eye-slash:before {
  content: '\e914';
}
.icon-eye:before {
  content: '\e915';
}
.icon-gallery-add:before {
  content: '\e913';
}
.icon-close-circle:before {
  content: '\e912';
}
.icon-check:before {
  content: '\e911';
}
.icon-tick-circle:before {
  content: '\e910';
}
.icon-profile-2user:before {
  content: '\e903';
}
.icon-arrow-right-alt:before {
  content: '\e900';
}
.icon-two-parallel-arrows:before {
  content: '\e901';
}
.icon-arrow-right:before {
  content: '\e902';
}
.icon-add:before {
  content: '\e904';
}
.icon-camera:before {
  content: '\e905';
}
.icon-gallery:before {
  content: '\e906';
}
.icon-times:before {
  content: '\e907';
}
.icon-add-circle:before {
  content: '\e908';
}
.icon-minus-circle:before {
  content: '\e909';
}
.icon-element-equal:before {
  content: '\e90a';
}
.icon-arrow-left:before {
  content: '\e90b';
}
.icon-calendar-2:before {
  content: '\e90c';
}
.icon-group:before {
  content: '\e90d';
}
.icon-profile:before {
  content: '\e90e';
}
.icon-search-status:before {
  content: '\e90f';
}
