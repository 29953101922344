.ion-color-primary-shade {
  --ion-color-base: var(--ion-color-primary-shade);
  --ion-color-base-rgb: var(--ion-color-primary-shade-rgb);
  --ion-color-contrast: var(--ion-color-primary-shade-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-shade-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade-shade);
  --ion-color-tint: var(--ion-color-primary-shade-tint);
}

.ion-color-tertiary-shade {
  --ion-color-base: var(--ion-color-tertiary-shade);
  --ion-color-base-rgb: var(--ion-color-tertiary-shade-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-shade-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-shade-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade-shade);
  --ion-color-tint: var(--ion-color-tertiary-shade-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-primary-tint {
  --ion-color-base: var(--ion-color-primary-tint);
  --ion-color-base-rgb: var(--ion-color-primary-tint-rgb);
  --ion-color-contrast: var(--ion-color-primary-tint-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-tint-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-tint-shade);
  --ion-color-tint: var(--ion-color-primary-tint-tint);
}

.ion-color-light-tint {
  --ion-color-base: var(--ion-color-light-tint);
  --ion-color-base-rgb: var(--ion-color-light-tint-rgb);
  --ion-color-contrast: var(--ion-color-light-tint-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-tint-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-tint-shade);
  --ion-color-tint: var(--ion-color-light-tint-tint);
}
