.dd-m-loading {
  --min-height: 100%;
  --min-width: 100%;
  --spinner-color: var(--ion-color-primary-shade);
  --background-color: var(--ion-color-white);

  ion-spinner {
    display: none;
  }

  .loading-spinner {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--ion-color-primary-shade);
    transition: background-color 0.1s ease-in;
    color: var(--ion-color-primary-shade);
    animation: dotFlashing 0.75s infinite linear alternate;
    animation-delay: 0.375s;
  }

  .loading-spinner::before,
  .loading-spinner::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .loading-spinner::before {
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--ion-color-primary-shade);
    transition: background-color 0.1s ease-in;
    color: var(--ion-color-primary-shade);
    animation: dotFlashing 0.75s infinite alternate;
    animation-delay: 0s;
  }

  .loading-spinner::after {
    left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--ion-color-primary-shade);
    transition: background-color 0.2s ease-in;
    color: var(--ion-color-primary-shade);
    animation: dotFlashing 0.75s infinite alternate;
    animation-delay: 0.75s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: var(--ion-color-primary-shade);
    }
    50% {
      background-color: rgba(var(--ion-color-primary-shade-rgb), 0.6);
    }
    100% {
      background-color: var(--ion-color-primary);
    }
  }
}
