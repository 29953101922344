ion-button {
  --border-radius: 50px;
  --padding-start: 24px;
  --padding-end: 24px;
  font-size: rem(16px);
  font-family: var(--font-secondary);
  font-weight: 800;
  line-height: 150%;
  letter-spacing: 0.01em;
  outline: none !important;
  margin: 0;

  &.--with-icon i {
    margin-left: 16px;
    font-size: rem(24px);
  }
}
