@import '../../../../theme/dindigital/base/mixins/rem';

.dd-m-input-otp {
  display: flex;
  justify-content: center;

  .otp-input {
    width: 100% !important;
    max-width: 48px !important;
    height: 52px !important;
    border-color: var(--ion-color-light-tint);
    background-color: var(--ion-color-white);
    outline: none;
    font-family: Mulish;
    font-weight: 600;
    font-size: rem(16px) !important;
    color: var(--ion-color-dark-shade);

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:focus-within {
      border-color: var(--ion-color-primary-shade);
    }
  }
}
