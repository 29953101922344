@import '../../base/mixins/rem';

ion-app.md .dd-m-toast {
  margin-top: 42px;
}

.dd-m-toast {
  --border-radius: 4px;
  --box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
  --color: var(--ion-color-white);
  --min-height: 48px;

  &::part(container) {
    position: relative;

    &::before {
      font-family: 'icomoon';
      content: '\e911';
      font-size: rem(16px);
      color: inherit;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: rgba(var(--ion-color-white-rgb), 0.25);
      border-radius: 50%;
      top: 14px;
      left: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &::part(message) {
    padding-left: 29px;
    font-weight: 500 !important;
    font-size: rem(13px) !important;
    letter-spacing: 0.01em;
  }

  &.--success {
    --background: var(--ion-color-success);

    &::part(container)::before {
      content: '\e911';
    }
  }

  &.--error {
    --background: var(--ion-color-danger);

    &::part(container)::before {
      content: '\e907';
    }
  }
}
