:root {
  /** primary - Din Digital **/
  --ion-color-primary: #bddbf7;
  --ion-color-primary-rgb: 189, 219, 247;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2287e5;
  --ion-color-primary-shade-rgb: 34, 135, 229;
  --ion-color-primary-tint: #e3f1fc;

  /** secondary - Din Digital **/
  --ion-color-secondary: #fcdbc2;
  --ion-color-secondary-rgb: 252, 219, 194;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #f58634;
  --ion-color-secondary-shade-rgb: 245, 134, 52;
  --ion-color-secondary-tint: #fef3eb;

  /** tertiary - Din Digital **/
  --ion-color-tertiary: #b9cee1;
  --ion-color-tertiary-rgb: 185, 206, 225;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #145088;
  --ion-color-tertiary-tint: #dce5ed;

  /** success - Din Digital **/
  --ion-color-success: #4fa36a;
  --ion-color-success-rgb: 79, 163, 106;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #468f5d;
  --ion-color-success-tint: #61ac79;

  /** warning - Ionic **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger - Din Digital **/
  --ion-color-danger: #d64568;
  --ion-color-danger-rgb: 214, 69, 104;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #bc3d5c;
  --ion-color-danger-tint: #da5877;

  /** dark - Din Digital **/
  --ion-color-dark: #817e8a;
  --ion-color-dark-rgb: 129, 126, 138;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #4e4b59;
  --ion-color-dark-tint: #9c9aa3;

  /** medium - Ionic **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light - Din Digital **/
  --ion-color-light: #d4d6d7;
  --ion-color-light-rgb: 212, 214, 215;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #b8bbbd;
  --ion-color-light-tint: #eeefef;
  --ion-color-light-tint-rgb: 238, 239, 239;

  /** primary-shade - Din Digital **/
  --ion-color-primary-shade: #2287e5;
  --ion-color-primary-shade-rgb: 34, 135, 229;
  --ion-color-primary-shade-contrast: #ffffff;
  --ion-color-primary-shade-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade-shade: #1e77ca;
  --ion-color-primary-shade-tint: #3893e8;

  /** tertiary-shade - Din Digital **/
  --ion-color-tertiary-shade: #145088;
  --ion-color-tertiary-shade-rgb: 20, 80, 136;
  --ion-color-tertiary-shade-contrast: #ffffff;
  --ion-color-tertiary-shade-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade-shade: #124678;
  --ion-color-tertiary-shade-tint: #2c6294;

  /** white - Din Digital **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** primary-tint - Din Digital **/
  --ion-color-primary-tint: #e3f1fc;
  --ion-color-primary-tint-rgb: 227, 241, 252;
  --ion-color-primary-tint-contrast: #000000;
  --ion-color-primary-tint-contrast-rgb: 0, 0, 0;
  --ion-color-primary-tint-shade: #c8d4de;
  --ion-color-primary-tint-tint: #e6f2fc;

  /** light-tint - Din Digital **/
  --ion-color-light-tint: #eeefef;
  --ion-color-light-tint-rgb: 238, 239, 239;
  --ion-color-light-tint-contrast: #000000;
  --ion-color-light-tint-contrast-rgb: 0, 0, 0;
  --ion-color-light-tint-shade: #d1d2d2;
  --ion-color-light-tint-tint: #f0f1f1;
}
