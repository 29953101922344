@import '../../base/mixins/rem';

.dd-m-modal {
  &.--bottom {
    align-items: flex-end;
  }

  &.--has-max {
    --height: 632px;
    --max-height: calc(100vh - 42px);
    --border-radius: 8px 8px 0px 0px;

    &.--small {
      --min-height: calc(280px + var(--ion-safe-area-bottom));
      --height: auto;
    }
  }

  ion-header ion-toolbar {
    --background: var(--ion-color-primary-contrast);
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 12px;
    --padding-bottom: 14px;
    padding-top: 0;

    ion-text {
      display: block;
      font-family: var(--font-secondary);
      font-weight: 700;
      font-size: rem(18px);
      color: var(--ion-color-dark-shade);
      padding-top: 37px;
      text-align: center;
    }
  }

  &__swipe-toggle {
    display: block;
    width: 40px;
    height: 4px;
    background-color: var(--ion-color-light);
    border-radius: 50px;
    margin: 0 auto;
  }

  ion-footer ion-toolbar {
    --min-height: var(--ion-safe-area-bottom);
    --background: var(--ion-color-primary-contrast);
  }
}

//Reseta os estilos que tiram a opacidade do backdrop quando um modal está dentro de outro.
ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}
